import { Injectable, inject } from '@angular/core';
import { FavoriteFull, FavoritesApi } from '@api';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, tap } from 'rxjs';
import { FavoritesActions } from './favorites.actions';

export interface FavoritesStateModel {
  favorites: FavoriteFull[];
  loaded: boolean;
}

@State<FavoritesStateModel>({
  name: 'favorites',
  defaults: {
    favorites: [],
    loaded: false,
  },
})
@Injectable()
export class FavoritesState {
  private favoritesAPI = inject(FavoritesApi);

  @Selector() static favorites(state: FavoritesStateModel) {
    return state.favorites;
  }

  @Selector()
  static isInFavorites(state: FavoritesStateModel) {
    return (uid: string | undefined) => {
      if (!uid) return false;
      return state.favorites.some(f => f.publicationId === uid);
    };
  }

  @Selector() static isLoaded(state: FavoritesStateModel) {
    return state.loaded;
  }

  @Action(FavoritesActions.FetchAll)
  retrieveAllFavorites(ctx: StateContext<FavoritesStateModel>) {
    if (ctx.getState().loaded) return;
    return this.favoritesAPI.findAllFavorites({}).pipe(
      tap(data => {
        ctx.patchState({ favorites: data, loaded: true });
      })
    );
  }

  @Action(FavoritesActions.Add)
  addFavorite(ctx: StateContext<FavoritesStateModel>, { publication }: FavoritesActions.Add) {
    const { favorites } = ctx.getState();
    const found = favorites.find(f => f.publicationId === publication.uid);
    if (found) return; // Already in favorites
    // add temp favorite
    let temp: FavoriteFull = {
      publication,
      publicationId: publication.uid,
      uid: '',
      status: true,
      createdAt: new Date(),
      updatedAt: new Date(),
      userId: '',
    };
    ctx.patchState({ favorites: [...favorites, temp] });
    return this.favoritesAPI
      .toogleFavorite({ createFavoriteDto: { publicationId: publication.uid, status: true } })
      .pipe(
        catchError(err => {
          ctx.patchState({ favorites: favorites.filter(f => f.publicationId !== publication.uid) });
          throw err;
        }),
        tap(favCreated => {
          const filterTemporary = favorites.filter(f => f.publicationId !== publication.uid);
          ctx.patchState({ favorites: [...filterTemporary, favCreated] });
        })
      );
  }

  @Action(FavoritesActions.Remove)
  removeFavorite(ctx: StateContext<FavoritesStateModel>, { uid }: FavoritesActions.Remove) {
    const favorites = ctx.getState().favorites;
    const found = favorites.find(f => f.publicationId === uid);
    if (!found) return;
    if (!found.uid) return;
    ctx.patchState({ favorites: favorites.filter(f => f.publicationId !== uid) });
    return this.favoritesAPI.toogleFavorite({
      createFavoriteDto: { publicationId: uid, status: false },
    });
  }

  @Action(FavoritesActions.Reset)
  resetFavorites(ctx: StateContext<FavoritesStateModel>) {
    ctx.setState({ favorites: [], loaded: false });
  }
}
